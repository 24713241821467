import { render, staticRenderFns } from "./BasicLayout.vue?vue&type=template&id=78100576&scoped=true&"
import script from "./BasicLayout.vue?vue&type=script&lang=js&"
export * from "./BasicLayout.vue?vue&type=script&lang=js&"
import style0 from "./BasicLayout.vue?vue&type=style&index=0&id=78100576&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78100576",
  null
  
)

export default component.exports