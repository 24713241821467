
const iconMap = {
    'home': '首页',
    'edit': '编辑',
    'copy': '复制',
    'delete': '删除',
    'close': '关闭',
    'admin': '管理员'
}

export default iconMap
