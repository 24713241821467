/* eslint-disable */
export default {
    'hello': 'hello',
    'home': 'home-page',
    'about': 'aboutus',
    'enLang': 'english',
    'zhlang': 'chinese',
    'recommendBroadcast': 'recommend broadcast'

}
